import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { queryRequest } from 'api';
import useOutsideClick from 'hooks/useOutsideClick';
import { common } from 'lang/en.json';
import { hideOverlay, showOverlay } from 'store/ux';
import styles from 'styles/modules/Nav.module.scss';
import linkStyles from 'styles/modules/NavMobile.module.scss';
import SearchResult from 'components/SearchResult';
import Close from 'components/Svg/Close';
import Logo from 'components/Svg/NewspageBrandLogo';
import Magnifier from 'components/Svg/Magnifier';
import World from 'components/Svg/World';
import NavigationLinks from './NavigationLinks';
import Search from './Search';
import { navigationSearchSelector } from 'store/navigation-search';
import { deauthenticate } from 'store/auth';
import { handleResetData } from 'store/company';
import NavDropdown from './Dropdown';

const Nav = () => {
    const dispatch = useDispatch();
    const router = useRouter();
    const { isAuthenticated } = useSelector(({ auth }) => auth);
    const { plan } = useSelector(({ company }) => company.data);
    const { search, toggle } = useSelector(navigationSearchSelector);
    const [mobileNavActive, setMobileNavActive] = useState(false);
    const [mobileSearchActive, setMobileSearchActive] = useState(false);

    const [results, setResults] = useState([]);
    const [resultsActive, setResultsActive] = useState(false);
    const [noResults, setNoResults] = useState(false);
    const [query, setQuery] = useState('');
    const [color, setColor] = useState('#2300ff');

    useEffect(() => {
        if (plan?.type === 'premium') {
            return setColor('#cab166');
        }
        if (plan?.type === 'legend') {
            return setColor('#cab166');
        }
        if (plan?.type === 'platinum') {
            return setColor('#ABABAB');
        }
        setColor('#2300ff');
    }, [plan]);

    const navRef = useRef(null);
    const mobileSearchInputRef = useRef(null);

    const handleMobileTrigger = () => {
        if (mobileNavActive) {
            closeMobileNav();
        } else if (mobileSearchActive) {
            closeMobileSearch();
        } else {
            openMobileNav();
        }
    };

    const openMobileNav = () => {
        setMobileNavActive(true);
        dispatch(showOverlay());
    };

    const closeMobileNav = () => {
        setMobileNavActive(false);
        dispatch(hideOverlay());
    };

    const openMobileSearch = () => {
        if (mobileNavActive) {
            setMobileNavActive(false);
        }

        setMobileSearchActive(true);
        dispatch(showOverlay());

        mobileSearchInputRef.current.focus();
    };

    const closeMobileSearch = () => {
        setMobileSearchActive(false);
        dispatch(hideOverlay());
        setResultsActive(false);
        setResults([]);
        setNoResults(false);
        setQuery('');
    };

    useOutsideClick(mobileSearchInputRef, () => {
        if (mobileSearchActive) {
            closeMobileSearch();
        }
    });

    useOutsideClick(navRef, () => {
        if (mobileNavActive) {
            closeMobileNav();
        }
    });

    const handleSearchInputChange = async ({ target: { value } }) => {
        setQuery(value);
        if (value.replace(/ /g, '').length > 3) {
            const { data, status } = await queryRequest('/search', {
                search_query: value,
            });
            if (status === 200) {
                setResults(data.data);
                setResultsActive(true);
                setNoResults(!data.data.length);
            }
        } else {
            setResults([]);
            setResultsActive(false);
        }
    };

    const handleResultClick = () => {
        if (mobileSearchActive) {
            setMobileSearchActive(false);
            setNoResults(false);
        }
        setQuery('');
        setResultsActive(false);
        setResults([]);
        dispatch(hideOverlay());
        dispatch(handleResetData());
    };

    useEffect(() => {
        if (search) {
            dispatch(showOverlay());
            handleSearchInputChange({ target: { value: search } });
            if (window.innerWidth < 1080) {
                //I dont know what to do here because outsideclick that closeMobileSearch gets triggered on click on tag that triggers this useEffect
                setTimeout(() => {
                    openMobileSearch();
                }, 300);
            }
        }
    }, [toggle]);

    const handleFocus = () =>
        results.length ? setResultsActive(true) : undefined;

    return (
        <nav
            className={[
                styles.nav,
                mobileSearchActive ? styles.mobileSearchActive : '',
                mobileNavActive ? styles.open : '',
            ].join(' ')}
            ref={navRef}
        >
            <div className={['container', styles.navContainer].join(' ')}>
                <Link href={'/'}>
                    <a className={styles.logo}>
                        <Logo
                            isLegend={plan ? plan.type === 'legend' : false}
                            color={color}
                        />
                    </a>
                </Link>

                <Search
                    onChange={handleSearchInputChange}
                    setResultsActive={setResultsActive}
                    resultsActive={resultsActive}
                    handleResult={handleResultClick}
                    onFocus={handleFocus}
                    query={query}
                    noResults={noResults}
                    results={results}
                />
                {router.pathname !== '/finish-sign-up' &&
                    (isAuthenticated ? (
                        <NavDropdown />
                    ) : (
                        <div className={styles.cta}>
                            <Link
                                href={
                                    router.pathname === '/register'
                                        ? '/register/company'
                                        : '/register'
                                }
                            >
                                <a className={[styles.btn].join(' ')}>
                                    {common.join}
                                </a>
                            </Link>
                        </div>
                    ))}

                <div className={styles.mobileSearch}>
                    <input
                        type="text"
                        className={styles.mobileSearchInput}
                        ref={mobileSearchInputRef}
                        value={query}
                        onChange={handleSearchInputChange}
                        onFocus={handleFocus}
                    />

                    <button
                        onClick={openMobileSearch}
                        className={styles.mobileSearchButton}
                    >
                        <Magnifier />
                    </button>

                    {resultsActive && (
                        <div className={styles.searchResults}>
                            {results.map((item, key) => {
                                return (
                                    <SearchResult
                                        key={`SearchResult-${key}`}
                                        item={item}
                                        onClick={handleResultClick}
                                    />
                                );
                            })}

                            {noResults && <SearchResult item={null} />}
                        </div>
                    )}
                </div>

                <div
                    className={[
                        styles.mobileTrigger,
                        mobileNavActive || mobileSearchActive
                            ? styles.mobileTriggerActive
                            : null,
                    ].join(' ')}
                    onClick={handleMobileTrigger}
                >
                    {[1, 2, 3].map((item) => (
                        <div
                            className={[
                                styles.mobileTriggerLine,
                                styles[`mobileTriggerLine${item}`],
                            ].join(' ')}
                            key={`hambuerger-line-${item}`}
                        />
                    ))}
                </div>
            </div>

            <div
                className={[
                    styles.mobileWrapper,
                    mobileNavActive ? styles.mobileWrapperActive : null,
                ].join(' ')}
            >
                <div
                    className={['container', styles.mobileInnerContainer].join(
                        ' '
                    )}
                >
                    {isAuthenticated ? (
                        <NavigationLinks
                            styles={linkStyles}
                            hideNav={handleMobileTrigger}
                            mobile={mobileNavActive}
                        />
                    ) : (
                        <a
                            className={linkStyles.btn}
                            onClick={() => {
                                router
                                    .push('/register')
                                    .then(() => window.scrollTo(0, 0));
                                handleMobileTrigger();
                            }}
                        >
                            <World fill={'#fff'} />
                            <span>{common.join}</span>
                        </a>
                    )}
                </div>
                <div
                    className={styles.mobileClose}
                    onClick={handleMobileTrigger}
                >
                    <Close />
                </div>
            </div>
        </nav>
    );
};
export default Nav;
