import TagManager from 'react-gtm-module';
import { DefaultSeo } from 'next-seo';
import SEO from 'next-seo.config';
import { useEffect } from 'react';
import 'styles/globals.scss';
import { wrapper } from 'store/index';
import NextNProgress from 'nextjs-progressbar';

import AppLayout from 'layouts/AppLayout';
import { useStore } from 'react-redux';
import auth from '../utils/auth';

const MyApp = ({ Component, pageProps }) => {
    useEffect(() => {
        TagManager.initialize({
            gtmId: 'GTM-W68FPWM',
        });
    }, []);

    const store = useStore();

    useEffect(() => {
        // Initializes auth logic, including interceptors when the app is first rendered
        auth(store);
    }, []);

    return (
        <>
            <DefaultSeo {...SEO} />
            <NextNProgress color="#3e20ff" />
            <AppLayout>
                <Component {...pageProps} />
            </AppLayout>
        </>
    );
};

// MyApp.getInitialProps = async (params) => {
//     // if (ctx.query.company) {
//     // }
//     // await initialize(ctx);
//     return {
//         pageProps: {
//             ...(params.Component.getInitialProps
//                 ? await params.Component.getInitialProps(params.ctx)
//                 : {}),
//         },
//     };
// };

export default wrapper.withRedux(MyApp);
